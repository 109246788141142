import "./preflight.css"; // Must come first to take lower specificity.
import "./index.css";
import { TelemetryProvider } from "@zus-health/ctw-component-library";
import { ErrorBoundary } from "ctw-common";
import React from "react";
import { createRoot } from "react-dom/client";
import { App } from "./App";
import { getPageEnv } from "./utils/get-page-env";
import { ZapConfigProvider } from "./zap-config/provider";

const isInsideIFrame = window.location !== window.parent.location;
const container = document.getElementById("root") as HTMLDivElement;

const searchParams = new URLSearchParams(window.location.search);

createRoot(container).render(
  <React.StrictMode>
    <ErrorBoundary title="Zus Aggregated Profile">
      <TelemetryProvider
        env={getPageEnv()}
        builderId={searchParams.get("builderId") ?? undefined}
        ehr={searchParams.get("ehr") ?? undefined}
        datadogConfig={{ version: import.meta.env.VITE_GIT_SHA }}
        enableTelemetry
      >
        <ZapConfigProvider>
          <App isInsideIFrame={isInsideIFrame} />
        </ZapConfigProvider>
      </TelemetryProvider>
    </ErrorBoundary>
  </React.StrictMode>,
);

import { ZapIFrameConfig } from "@zus-health/ctw-component-library";
import { PropsWithChildren, useMemo, useState } from "react";
import { ZapConfigContext } from "./context";

type ZapConfigProviderProps = object;

export function ZapConfigProvider({ children }: PropsWithChildren<ZapConfigProviderProps>) {
  const [zapConfig, setZapConfig] = useState<ZapIFrameConfig>();

  const value = useMemo(
    () => ({
      zapConfig,
      setZapConfig,
    }),
    [zapConfig, setZapConfig],
  );
  return <ZapConfigContext.Provider value={value}>{children}</ZapConfigContext.Provider>;
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IFrameErrorMessage } from "./iframe-error-message";
import { ZapV1 } from "./zaps/zap-v1";
import { ZapV2 } from "./zaps/zap-v2";

type AppProps = {
  isInsideIFrame: boolean;
};

export function App({ isInsideIFrame }: AppProps) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<ZapV1 />} />
        <Route path="/v1" element={<ZapV1 />} />
        <Route path="/v2" element={<ZapV2 />} />
        <Route path="/health" element={<>OK</>} />
      </Routes>
      {!isInsideIFrame && <IFrameErrorMessage />}
    </BrowserRouter>
  );
}

import { ZapIFrameConfig } from "@zus-health/ctw-component-library";
import { createContext } from "react";

export type ZapConfigContextProps = {
  zapConfig?: ZapIFrameConfig;
  setZapConfig: (config: ZapIFrameConfig) => void;
};

export const ZapConfigContext = createContext<ZapConfigContextProps>({
  setZapConfig: () => {},
});

import PageError from "./assets/page-error.svg";

export function IFrameErrorMessage() {
  return (
    <div className="ctw-mt-20 ctw-flex ctw-flex-col ctw-lex-wrap ctw-space-y-12 ctw-sm:ml-20 ctw-lg:ml-80 ml-10">
      <img
        src={PageError}
        alt="Error icon displaying a circled X over a document"
        width="80"
        height="80"
      />
      <div className="ctw-space-y-6">
        <div className="ctw-text-2xl ctw-font-medium">Proxy Authentication Required</div>
        <div className="ctw-text-base ctw-font-medium">
          This page will only authenticate and load when embedded inside an iframe. For assistance,
          get in contact with your representative at Zus Health.
        </div>
        <div className="ctw-text-xs ctw-font-medium ctw-text-gray-500">HTTP ERROR 407</div>
        <div>
          <a
            className="ctw-btn-primary zap-case-normal ctw-w-fit"
            href="mailto:support@zushealth.com?subject=Help%20Embedding%20Zus%20Aggregated%20Profile"
          >
            Contact support@zushealth.com
          </a>
        </div>
      </div>
    </div>
  );
}

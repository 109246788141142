import { Component, ErrorInfo, ReactNode } from "react";

interface Props {
  title?: string;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo?: ErrorInfo;
  error?: unknown;
}

export class ErrorBoundary extends Component<Props, State> {
  public static getDerivedStateFromError(error: unknown): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  public state: State = {
    hasError: false,
  };

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    const { hasError, error } = this.state;
    const { children, title} = this.props;

    if (!hasError) {
      return children;
    }

    const errorMessage =
      error instanceof Error ? error.message : "Unknown error";

    return (
      <div className="ctw-mt-20 ctw-flex ctw-flex-col ctw-lex-wrap ctw-space-y-12 ctw-sm:ml-20 ctw-lg:ml-80 ml-10">
        <div className="ctw-space-y-6 ctw-p-3 ctw-align-middle">
          <div className="ctw-text-2xl ctw-font-medium">{title || "Error Boundary"}</div>
          <div className="ctw-text-base ctw-font-medium">Uncaught Error</div>
          <div className="ctw-text-xs ctw-font-medium ctw-text-gray-500">
            {errorMessage}
          </div>
          <div>
            <a
              style={{ textTransform: "none" }}
              className="ctw-btn-primary ctw-case-normal ctw-w-fit"
              href="mailto:support@zushealth.com?subject=Uncaught%20Error%20Zus%20Aggregated%20Profile"
            >
              Contact support@zushealth.com
            </a>
          </div>
        </div>
      </div>
    );
  }
}
